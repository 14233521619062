.file-card {
  width: 200px; 
  height: 80px;          /* Make the card fill its container */
  margin: 0 ;         /* Center the card */
  padding: 4px;          /* Add padding inside the card */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  /* Add elevation */
  border-radius: 8px;     /* Slightly round the corners */
  transition: transform 0.2s ease-in-out;  /* Smooth hover effect */
}

.error-file-card {
  background: #ff7f84;
  background-color: #ff7f84;
  width: 250px; 
  height: 80px;          /* Make the card fill its container */
  margin: 0 ;         /* Center the card */
  padding: 0px;          /* Add padding inside the card */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  /* Add elevation */
  border-radius: 4px;     /* Slightly round the corners */
  transition: transform 0.2s ease-in-out;  /* Smooth hover effect */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.success-file-card{
  background: #a1dda1;
  background-color: #a1dda1;
  width: 250px;
  height: 80px;
  margin: 0 ; 
  padding: 0px;       
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.base-card{
  background: transparent;
  background-color: transparent;
  width: 250px;
  height: 80px;
}