.content-logo {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.btn-transparent, .btn-transparent:hover{
  background-color: transparent;
  color: #212529;
}

.p-error-login{
  padding: 2px;
  background:#DB7161;
  width: 100%;
  margin: 5px auto;
  text-align: center;
  color: rgb(255, 255, 255);
  font-weight: bold;
  border-radius: 4px;
}