.home-title {
  font-size: 24px;
  margin-bottom: 30px;
}

.home-card {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  padding: 20px;
}

.home-card:hover {
  transform: scale(1.05);
  background-color: #f8f9fa; /* Light background on hover */
}

.floating-icon {
  position: fixed;
  top: 20px;
  left: 20px; /* Adjust these values for positioning */
  background-color: #f8f9fa; /* Optional: add background */
  padding: 10px;
  border-radius: 50%; /* Makes it circular */
  cursor: pointer;
  z-index: 1000; /* Ensures it stays above other content */
  transition: background-color 0.3s ease;
}

.floating-icon:hover {
  background-color: #e0e0e0; /* Optional: hover effect */
}