table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #000; /* Maintain grid lines */
  padding: 8px;
  position: relative; /* Ensure relative positioning for tooltip */
}

td.basic{
  border: 1px solid #000; /* Maintain grid lines */
  padding: 8px;
  position: relative;
}

td.na-cell {
  background-color: #f0f0f0; /* Gray background for N/A cells */
  color: gray; /* Gray text color */
  border: 1px solid #000; /* Maintain grid lines */
  padding: 8px;
  position: relative;
}

.has-error {
  background-color: #f8d7da; /* Light red background for cells with errors */
  color: red; /* Highlight text in red */
  cursor: help;
  border: 1px solid #000;
  padding: 8px; /* Change cursor type when hovering over cells with errors */
  position: relative;
}

.has-warning {
  background-color: #fff3cd; /* Light yellow background for cells with warnings */
  color: #856404; /* Highlight text in yellow */
  cursor: help;
  border: 1px solid #000;
  padding: 8px; /* Change cursor type when hovering over cells with warnings */
  position: relative;
}

.error-tooltip {
  visibility: hidden;
  opacity: 0;
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: auto; 
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.2;
  transition: opacity 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: none; 
}

.has-error:hover .error-tooltip {
  visibility: visible;
  opacity: 1;
}

.has-error:hover .error-tooltip::after {
  content: '';
  position: absolute;
  top: auto;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #f8d7da transparent;
}

.warning-tooltip {
  visibility: hidden;
  opacity: 0;
  background-color: #fff3cd;
  color: #856404;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: auto; 
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.2;
  transition: opacity 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: none; 
}

.has-warning:hover .warning-tooltip {
  visibility: visible;
  opacity: 1;
}

.has-warning:hover .warning-tooltip::after {
  content: '';
  position: absolute;
  top: auto;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff3cd transparent;
}

td.checkbox-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;
}

input[type="checkbox"]:checked {
  background-color: #28a745; /* Green background */
  border-color: #28a745;
}

input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

input[type="checkbox"]:hover {
  border-color: #999;
}