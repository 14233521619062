ul li{
    margin-top: 1.5rem;
}

.title-module{
    margin: 0 !important;
}

.c-title{
    margin: 0;
    line-height: 2;
    margin-right: 20px;
}

.border-top{
    border-top: 1px solid rgba(0, 0, 0, 0.175);
}

.btn-upload-file{
    margin-left: 20px;
    margin-top: 8px;
    display: flex;
    justify-content: center!important;
    height: 40px;
}

.btn-upload-file svg{
    font-size: 20px;
    margin-top: 2px;
}

.btn-upload-file p{
    margin: 1px 0 0 10px !important;
}

.btn-validate-file p{
    margin: 0;
}

.file-name{
    margin-left: 20px;
    margin-top: 13px;
}

.btn-confirm{
    margin: 0 auto;
}

.card-footer{
    background-color: #fff;
    border: none;
}